import { Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import "./App.css";
import Failed from "./Failed.jsx";
import Login from "./Login.jsx";
import Feedback from "./Feedback.jsx";
import Modul from "./Modul.jsx";
import Logout from "./Logout.jsx";
import { useNavigate } from "react-router-dom";
import Modul2 from "./modul2.jsx";
import Footer from "./Footer.jsx";

function App() {
  const [User, setUser] = useState("");
  const [Password, setPassword] = useState("");
  const [Ident, setIdent] = useState("Bitte einloggen");
  const [Sem, setSem] = useState(1);
  const navigate = useNavigate();
  const [selectedModule, setSelectedModule] = useState("");
  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };
  const [Text, setText] = useState("");

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Login
            navigate={navigate}
            Ident={Ident}
            setIdent={setIdent}
            User={User}
            setUser={setUser}
            Password={Password}
            setPassword={setPassword}
            Sem={Sem}
            setSem={setSem}
          />
        }
      />
      <Route
        path="/Failed"
        element={
          <Failed
            navigate={navigate}
            Ident={Ident}
            User={User}
            Password={Password}
          />
        }
      />
      <Route
        path="/Modul"
        element={
          <Modul
            User={User}
            Password={Password}
            navigate={navigate}
            Sem={Sem}
            selectedModule={selectedModule}
            setSelectedModule={setSelectedModule}
            handleModuleChange={handleModuleChange}
          />
        }
      />
      <Route
        path="/Modul2"
        element={
          <Modul2
            User={User}
            Password={Password}
            navigate={navigate}
            Sem={Sem}
            selectedModule={selectedModule}
            setSelectedModule={setSelectedModule}
            handleModuleChange={handleModuleChange}
          />
        }
      />
      <Route
        path="/Feedback"
        element={
          <Feedback
            Text={Text}
            setText={setText}
            User={User}
            Password={Password}
            navigate={navigate}
            selectedModule={selectedModule}
            Sem={Sem}
          />
        }
      />
      <Route
        path="/Logout"
        element={
          <Logout
            User={User}
            setUser={setUser}
            Password={Password}
            setPassword={setPassword}
            navigate={navigate}
          />
        }
      />
    </Routes>
  );
}

export default App;
