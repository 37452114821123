import "./App.css";
import Header from "./Header2.jsx";
import Button from "@mui/material/Button";
import {
  TextField,
  Slider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Footer from "./Footer.jsx";
function Modul(props) {
  const Weiter = () => {
    props.navigate("/Feedback");
  };
  const directoryArray = [
    { id: 1, number: 1010, name: "Kommunikation, Umwelt, Nachhaltigkeit" },
    { id: 1, number: 1020, name: "Mathematik I" },
    { id: 1, number: 1030, name: "Statistik I" },
    { id: 1, number: 1040, name: "Aaufnahme und Analyse von Geodaten" },
    { id: 1, number: 1050, name: "Einführung in die Datenmodellierung" },
    { id: 1, number: 1060, name: "Informatik Grundlagen" },
    { id: 1, number: 1070, name: "Englisch I" },
    { id: 2, number: 2010, name: "Recht & Ethik" },
    { id: 2, number: 2020, name: "Mthematik II" },
    { id: 2, number: 2030, name: "Statistik II" },
    { id: 2, number: 2040, name: "Berührungslose Messsysteme" },
    { id: 2, number: 2050, name: "Geografische Informationssysteme" },
    { id: 2, number: 2060, name: "Geoprogrammierung I" },
    { id: 2, number: 2070, name: "Geovisualisierung" },
    { id: 2, number: 2080, name: "Englisch II" },
    { id: 3, number: 3010, name: "Mathematik III" },
    { id: 3, number: 3020, name: "Physik" },
    { id: 3, number: 3030, name: "3D-Datenauswertung" },
    { id: 3, number: 3040, name: "Räumliche Datenverwaltung und Datenanalyse" },
    {
      id: 3,
      number: 3050,
      name: "Webprogrammierung und interaktive Datenvisualisierung",
    },
    { id: 3, number: 3060, name: "Grundzüge VDC" },
    { id: 3, number: 3070, name: "Feldkurs Vermessung" },
    { id: 3, number: 3080, name: "Englisch III" },
    { id: 4, number: 4010, name: "Recht und Digitalisierung" },
    { id: 4, number: 4020, name: "Globale Positionierung" },
    { id: 4, number: 4030, name: "3D-Modellierung" },
    { id: 4, number: 4040, name: "Geoprogrammierung II" },
    { id: 4, number: 4050, name: "Photogrammetrie und Computer Vision" },
    { id: 4, number: 4060, name: "Projektmanagement I" },
    { id: 4, number: 4070, name: "Datenaustausch und Interoperabilität" },
    { id: 4, number: 4200, name: "Profilmodule I" },
    { id: 5, number: 5010, name: "Geomatik-Recht" },
    { id: 5, number: 5020, name: "Neuronale Netze und künstliche Intelligenz" },
    { id: 5, number: 5030, name: "Fernerkundung" },
    { id: 5, number: 5040, name: "Projektmanagement II" },
    { id: 5, number: 5050, name: "Feldkurs Vertiefungsprofil" },
    { id: 5, number: 5200, name: "Profilmodule II" },
    { id: 6, number: 6000, name: "Bachelor-Thesis" },
    { id: 6, number: 6020, name: "Projektmanagement" },
    { id: 6, number: 6200, name: "Profilmodule III" },
  ];
  const Modulwahl = directoryArray.filter((item) => item.id === props.Sem);

  return (
    <div>
      <Header User={props.User} Password={props.Password} />

      <div className="PageWrapper">
        <div className="FlexPage">
          <div className="row">
            <h1>Bitte Modul auswählen.</h1>
          </div>
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Box>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.SelectedModule}
              label={props.SelectedModule}
              onChange={props.handleModuleChange}
              style={{ width: "400px", height: "40px" }}
            >
              {Modulwahl.map((module) => (
                <MenuItem key={module.number} value={module.name}>
                  {module.name}
                </MenuItem>
              ))}
            </Select>
            <Box mt={2} />

            <div className="row" style={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={Weiter}
                style={{ color: "black", backgroundColor: "#FDE70E" }}
              >
                Weiter
              </Button>
            </div>
          </Box>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Modul;
