import React from "react";
import logofhnw from "./logofhnw.png";
import "./App.css";

function Header(props) {
  const headerStyle = {
    borderBottom: "2px solid #000000",
    paddingBottom: "10px",
    marginBottom: "10px",
  };
  return (
    <header
      className="App-header"
      style={{
        backgroundColor: "#FDE70E",
        borderBottom: "2px solid #000000",
      }}
    >
      <img style={{ height: 40,marginLeft:"40px" }} src={logofhnw} alt="logo" />
      <div id="HeaderText" style={{ color: "black" }}>
        Feedback Plattform FHNW Bsc Geomatik
      </div>
      <div id="HeaderText" style={{ color: "black", marginRight:"40px"}}>
        {props.User} {props.Password}
      </div>
    </header>
  );
}

export default Header;
